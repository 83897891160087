<template>
  <section class="container py-5 rounded card-login">
    <div class="row">
      <div class="col-12 col-md-4 img"></div>
      <div class="col-12 col-md-8 form bg-dark">
        <h1 class="mb-1 mt-5">Sign in</h1>
        <p class="text-muted mb-3">
          We'll never share your email with anyone else.
        </p>
        <form class="form" @submit.stop.prevent="onSubmit">
          <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert alert-danger alert-dismissible fade"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <b-form-input
              class="form-control col-12 col-md-6"
              id="email"
              name="email"
              placeholder="Email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="email-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="email-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <b-form-input
              class="form-control col-12 col-md-6"
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="password-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="password-live-feedback">
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex align-items-center">
            <button type="submit" class="btn btn-sm btn-success mr-3">
              <i class="fas fa-sign-in-alt"></i> Login</button
            ><a href="#" class="d-block">Forgot Password?</a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { LOGIN, LOGOUT } from "@/store/auth.module";
import { mapState } from "vuex";
export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      imgPath: "/media/",
      form: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  mounted() {
    localStorage.clear();
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(data => {
            if (data.user.password_reset == 1)
              this.$router.push({ name: "Dashboard" });
            else this.$router.push({ name: "ResetPassword" });
          });
      }, 2000);
    }
  }
};
</script>
<style lang="scss" scoped>
.img {
  background-image: url(/images/assets/login.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.form {
  min-height: 300px;
  .form-control,
  .btn,
  a {
    font-size: 12px !important;
  }
}
.card-login {
  color: #fdfdfd;
}
.bg-dark {
  background-color: #161109 !important;
}
a {
  color: #fdfdfd;
  &:hover {
    color: aqua;
  }
}
</style>
